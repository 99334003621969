/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.hide-mobile {
  display: block !important;
}

.mobile-only {
  display: none !important;
}

.gap-15 {
  gap: 15px;
}

.w-100 {
  width: 100%;
}

@media screen and (max-width: 991px) {
  .hide-mobile {
    display: none !important;
  }
  .mobile-only {
    display: block !important;
  }
}

.ag-cell {
  user-select: text !important;
}

.ag-header {
  background-color: #f8f8f8 !important;
}
